import { tableOptions } from '@/utils/table.util'
import { formatedDate } from '@/utils/date.util'
import { downloadFile } from '@/utils/utilities.util'
import i18n from '@/i18n/i18n'
import {
  getProductsApi,
  postCreateProductTouchpointApi,
  postSearchTouchpointApi,
  postCreateCustomTouchpointApi,
  getBrandSettingsApi,
  getSearchFiltersApi,
  getTouchpointApi,
  postUpdateTouchpointApi,
  postDownloadZipApi,
  postExportCSVApi,
  postImportCSVApi,
  postRefreshLPApi,
} from '@/services/touchpoints.service'

const SET_CURRENT_TOUCHPOINT = 'SET_CURRENT_TOUCHPOINT'
const SET_BRAND_SETTING = 'SET_BRAND_SETTING'
const ADD_PRODUCT = 'ADD_PRODUCT'
const SORT_PRODUCTS = 'SORT_PRODUCTS'
const RESET_PRODUCTS = 'RESET_PRODUCTS'
const ADD_TOUCHPOINTS = 'ADD_TOUCHPOINTS'
const SET_TOUCHPOINTS_PAGINATION = 'SET_TOUCHPOINTS_PAGINATION'
const SET_FILTERS = 'SET_FILTERS'
const SET_FILTERS_RULES = 'SET_FILTERS_RULES'
const SET_CURRENT_FILTERS = 'SET_CURRENT_FILTERS'
const SET_SEARCH_RULES = 'SET_SEARCH_RULES'
const SET_SEARCH = 'SET_SEARCH'
const SET_DOWNLOAD_URL = 'SET_DOWNLOAD_URL'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    currentTouchpoint: null,
    products: [],
    touchpoints: [],
    brandSettings: [],
    touchpointsPagination: {
      page: 1,
      itemsPerPage: 25,
      total: 0,
    },
    currentFilters: [],
    filters: [],
    searchRules: [],
    filtersRules: [],
    search: '',
    downloadUrl: '',
  }
}

const state = getDefaultState()

const mutations = {
  [SET_CURRENT_TOUCHPOINT]: (state, touchpoint) => {
    state.currentTouchpoint = touchpoint
  },
  [SET_BRAND_SETTING]: (state, brandSettings) => {
    state.brandSettings = brandSettings
  },
  [ADD_PRODUCT]: (state, product) => {
    state.products.push(product)
  },
  [SORT_PRODUCTS]: (state, references) => {
    const productPosition = {}
    for (const [index, id] of references.entries()) {
      productPosition[id.toUpperCase()] = index
    }
    state.products.sort((a, b) => productPosition[a.sku] - productPosition[b.sku])
  },
  [RESET_PRODUCTS]: state => {
    state.products = []
  },
  [ADD_TOUCHPOINTS]: (state, touchpoints) => {
    state.touchpoints = touchpoints
  },
  [SET_TOUCHPOINTS_PAGINATION]: (state, pagination) => {
    state.touchpointsPagination = {
      ...state.touchpointsPagination,
      ...pagination,
    }
  },
  [SET_FILTERS]: (state, filters) => {
    state.filters = filters
  },
  [SET_FILTERS_RULES]: (state, filtersRules) => {
    state.filtersRules = filtersRules
  },
  [SET_CURRENT_FILTERS]: (state, currentFilters) => {
    state.currentFilters = currentFilters
  },
  [SET_SEARCH_RULES]: (state, searchRules) => {
    state.searchRules = searchRules
  },
  [SET_SEARCH]: (state, search) => {
    state.search = search
  },
  [SET_DOWNLOAD_URL]: (state, downloadUrl) => {
    state.downloadUrl = downloadUrl
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  //Brand
  async getBrandSettings({ commit, rootState }) {
    const brandSettings = await getBrandSettingsApi(rootState.backoffice.currentClient.brandId)
    commit(SET_BRAND_SETTING, brandSettings)
  },
  //Products
  async getProduct({ commit, rootState }, sku) {
    try {
      const { data: product } = await getProductsApi(rootState.backoffice.currentClient.brandId, sku)
      const foundProduct = {
        ...product,
        status: 'OK',
      }
      commit(ADD_PRODUCT, foundProduct)
    } catch {
      const notFoundProduct = {
        sku,
        productBrand: '-',
        name: '-',
        status: 'KO',
        touchpointConfiguration: {
          activated: false,
        },
      }
      commit(ADD_PRODUCT, notFoundProduct)
    }
  },
  sortProducts({ commit }, references) {
    commit(SORT_PRODUCTS, references)
  },
  resetProducts({ commit }) {
    commit(RESET_PRODUCTS)
  },
  //Touchpoints
  async addProductTouchpoint({ rootState }, sku) {
    await postCreateProductTouchpointApi(rootState.backoffice.currentClient.brandId, sku)
  },

  async addCustomTouchpoint({ rootState }, touchpoint) {
    const { data } = await postCreateCustomTouchpointApi(rootState.backoffice.currentClient.brandId, {
      brandId: rootState.backoffice.currentClient.brandId,
      ...touchpoint,
    })
    return data
  },

  async getTouchpoints({ rootState }, skus) {
    const filters = [
      {
        field: 'product.sku',
        values: skus,
        operator: 'in',
      },
    ]
    const { data } = await postSearchTouchpointApi(rootState.backoffice.currentClient.brandId, filters)
    return data.rows
  },
  async getTouchpoint({ commit, rootState }, id) {
    const { data } = await getTouchpointApi(rootState.backoffice.currentClient.brandId, id)
    commit(SET_CURRENT_TOUCHPOINT, data)
  },
  async updateTouchpoint({ commit }, touchpoint) {
    const { data } = await postUpdateTouchpointApi(touchpoint)
    commit(SET_CURRENT_TOUCHPOINT, data)
  },
  async searchAllTouchpoints({ commit, rootState, state }, { tableParams }) {
    const urlParams = tableOptions(tableParams, 'touchpoints')
    const { data: touchpoints } = await postSearchTouchpointApi(
      rootState.backoffice.currentClient.brandId,
      state.searchRules,
      state.filtersRules,
      urlParams
    )
    const formattedRows = touchpoints.rows.map(row => {
      return {
        ...row,
        stats: {
          hits: row.stats?.hits || 0,
          gameStarted: row.stats?.gameStarted || 0,
          gameEnded: row.stats?.gameEnded || 0,
        },
      }
    })
    commit(ADD_TOUCHPOINTS, formattedRows)
    delete touchpoints.rows
    commit(SET_TOUCHPOINTS_PAGINATION, touchpoints)
  },

  async getFilters({ commit, rootState }) {
    const { data: filters } = await getSearchFiltersApi(rootState.backoffice.currentClient.brandId)
    commit(SET_FILTERS, filters)
  },

  setFiltersRules({ commit }, filtersRules) {
    commit(SET_FILTERS_RULES, filtersRules)
  },
  setCurrentFilters({ commit }, currentFilters) {
    commit(SET_CURRENT_FILTERS, currentFilters)
  },
  setSearchRules({ commit }, searchRules) {
    commit(SET_SEARCH_RULES, searchRules)
  },
  setSearch({ commit }, search) {
    commit(SET_SEARCH, search)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },

  async getDownloadUrl({ dispatch, rootState, state }, { _ids, filters }) {
    dispatch('backoffice/setWorking', true, { root: true })
    try {
      const { data } = await postDownloadZipApi(
        rootState.backoffice.currentClient.brandId,
        _ids,
        state.searchRules,
        filters
      )

      dispatch('backoffice/setWorking', false, { root: true })
      return data.zipUrl
    } catch {
      dispatch(
        'backoffice/setAlert',
        {
          color: 'error',
          text: i18n.t('error.notification.default'),
        },
        { root: true }
      )

      dispatch('backoffice/setWorking', false, { root: true })
    }
  },

  async getCSVDownloadUrl({ dispatch, rootState, state }, { _ids, filters }) {
    dispatch('backoffice/setWorking', true, { root: true })
    try {
      const { data } = await postExportCSVApi(
        rootState.backoffice.currentClient.brandId,
        _ids,
        state.searchRules,
        filters
      )
      const fileName = `export_${formatedDate('now', 'DDMMYYYY')}`
      let fileExtension = 'csv'
      downloadFile(data, `${fileName}.${fileExtension}`)
    } catch {
      dispatch(
        'backoffice/setAlert',
        {
          color: 'error',
          text: i18n.t('error.notification.default'),
        },
        { root: true }
      )
    }
    dispatch('backoffice/setWorking', false, { root: true })
  },

  async sendFile({ rootState }, formData) {
    await postImportCSVApi(rootState.backoffice.currentClient.brandId, formData)
  },

  async refreshLP({ dispatch, rootState, state }, { _ids, filters }) {
    dispatch('backoffice/setWorking', true, { root: true })
    try {
      await postRefreshLPApi(rootState.backoffice.currentClient.brandId, _ids, state.searchRules, filters)
      dispatch(
        'backoffice/setAlert',
        {
          color: 'success',
          text: i18n.t('touchpoints.management.notification.lpsuccess'),
        },
        { root: true }
      )
    } catch {
      dispatch(
        'backoffice/setAlert',
        {
          color: 'error',
          text: i18n.t('error.notification.default'),
        },
        { root: true }
      )
    }
    dispatch('backoffice/setWorking', false, { root: true })
  },
}

const getters = {
  totalTouchpoints: state => {
    return state.touchpointsPagination?.total || 0
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
